.monitoring-sec {
  display: flex;
  flex-direction: column;
  row-gap: 27px;
  position: relative;
  border-radius: 9.977px;
  background: var(--white);
  padding: 20px;
  .top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 17px;
    .active-circle {
      border-radius: 500px;
      background: rgba(12, 165, 90, 0.1);
      padding: 13px;
      min-width: 73px;
      min-height: 73px;
    }
    .info {
      background: var(--lightwhite);
      padding: 35px 21px 28px 26px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 17px;
    .left {
      display: flex;
      flex-direction: column;
      background: var(--lightwhite);
      border-radius: 16px;
      .customers-head {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        padding: 20px 20px 9px 20px;
        border-bottom: 1px solid rgba(103, 103, 103, 0.2);
        .search-bar {
          border-radius: 9.977px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: var(--lightwhite);
          display: flex;
          align-items: center;
          column-gap: 12px;
          padding: 15px 16px;
          input {
            width: 100%;
            outline: none;
            border: none;
            background: transparent;
            font-family: "Carmen Sans Regular";
            font-weight: 400;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            color: var(--grey);
          }
        }
      }
      .customers-list {
        display: flex;
        flex-direction: column;
        padding: 20px 14px;
        max-height: 400px;
        overflow-y: auto;
        .active {
          border-radius: 16px;
          border-bottom: 1px solid var(--secondary);
          color: var(--primary);
          background: rgba(12, 165, 90, 0.1);
          img {
            filter: brightness(0) saturate(100%) invert(39%) sepia(87%)
              saturate(644%) hue-rotate(91deg) brightness(89%) contrast(91%);
          }
        }
        div {
          display: flex;
          align-items: center;
          column-gap: 10px;
          padding: 16px 20px;
          border-bottom: 1px solid rgba(103, 103, 103, 0.2);
        }
      }
    }
    .center {
      display: flex;
      flex-direction: column;
      row-gap: 18px;
      .call-recordings {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        padding: 20px;
        border-radius: 16px;
        background: var(--lightwhite);

        .all-recordings {
          .recording {
            padding: 16px 8px;
            border-radius: 8px;
            background: var(--white);
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            .recording-top {
              display: flex;
              align-items: center;
              column-gap: 8px;
              div {
                width: 100%;
                cursor: pointer;
                display: flex;
                align-items: center;
                img {
                  width: 100%;
                  object-fit: cover;
                }
                svg {
                  width: 24px;
                  height: 24px;
                  object-fit: cover;
                }
              }
            }
            .recording-bottom {
              display: flex;
              align-items: center;
              justify-content: space-between;
              column-gap: 20px;
              svg {
                width: 28px;
                height: 28px;
                object-fit: cover;
              }
            }
          }
        }
        .bg-white {
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          .specific-color {
            border-radius: 16px;
            color: var(--primary);
            background: rgba(12, 165, 90, 0.1);
            padding: 8px 20px;
            line-height: 100%;
          }
        }
        .customscrollbar {
          height: 350px;
          overflow-y: auto;
        }
        .empty-customscrollbar {
          height: 366px;
          overflow-y: auto;
        }
        .add-div {
          display: flex;
          align-items: center;
          column-gap: 10px;
          border-radius: 8px;
          background: var(--gradient);
          position: relative;
          padding: 10px 18px;
          width: fit-content;
          margin-left: auto;
          .model-content {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 200px;
            right: 0;
            top: 50px;
            border-radius: 9.977px;
            background: var(--lightwhite);
            transition: all 0.3s linear;
            border: 1px solid rgba(0, 0, 0, 0.2);

            p {
              text-align: center;
              padding: 10px 0px;
              cursor: pointer;
              border-bottom: 1px solid rgba(0, 0, 0, 0.2);
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .modal-hide {
            display: none;
          }
          .modal-show {
            display: block;
          }
        }
      }
      .transcribing {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        padding: 10px 20px 40px 20px;
        border-radius: 16px;
        background: var(--lightwhite);
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      row-gap: 18px;
      padding: 20px;
      border-radius: 16px;
      background: var(--lightwhite);
      div {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
    }
  }
}
.transfercalls-modal {
  .modal-dialog {
    max-width: 633px !important;
    .modal-content {
      .modal-body {
        padding: 20px 47px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 34px;
        position: relative;
        .modal-inner {
          display: flex;
          flex-direction: column;
          row-gap: 6px;
          .transfer-div {
            width: 42px;
            display: flex;
            img {
              width: 100%;
              object-fit: cover;
            }
          }
          .customers-div {
            display: flex;
            align-items: center;
            column-gap: 12px;
            width: 223px;
          }
          .modal-head {
            display: flex;
            column-gap: 18px;
            display: flex;
            align-items: center;
          }
          .modal-details {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            max-height: 445px;
            overflow-y: auto;
            .customers-div {
              border-radius: 9.977px;
              background: var(--lightwhite);
              padding: 13px 20px;
            }
            .detail {
              display: flex;
              column-gap: 18px;
              display: flex;
              align-items: center;
            }
          }
        }
        .close-btn {
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .monitoring-sec {
    row-gap: 20px;
    .top {
      column-gap: 10px;
      .info {
        padding: 30px 20px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        h4 {
          text-align: center;
        }
      }
    }
    .bottom {
      grid-template-columns: 1fr 2fr;
      column-gap: 10px;
      .left {
        .customers-head {
          row-gap: 15px;
          padding: 20px 15px 9px 15px;
          .search-bar {
            padding: 10px;
            column-gap: 5px;
            input {
              font-size: 12px;
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        .customers-list {
          padding: 15px 5px;
          img {
            object-fit: cover;
            width: 22px;
            height: 22px;
          }
          div {
            column-gap: 8px;
            padding: 10px 15px;
          }
        }
      }
      .center {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        .call-recordings {
          row-gap: 15px;
          .all-recordings {
            .recording {
              .recording-top {
                div {
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
              .recording-bottom {
                column-gap: 20px;
                svg {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
        .transcribing {
          row-gap: 10px;
          padding: 10px 20px 30px 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .monitoring-sec {
    padding: 20px 10px;
    .top {
      column-gap: 10px;
      .info {
        padding: 20px 18px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .monitoring-sec {
    max-width: 400px;
    margin: 0px auto;
    .top {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
    .bottom {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .center {
        .call-recordings {
          padding: 15px;
        }
      }
    }
  }
}
