.compaignsInrun-sec {
  border-radius: 16px;
  background: var(--white);
  padding: 20px 10px 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }
  .contacts {
    padding: 20px;
    border-radius: 16px;
    background: var(--lightwhite);
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    .dates {
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      // justify-content: space-between;
      gap: 20px;
      .dates-inner {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        max-width: 300px;
        min-width: 200px;
        width: 100%;
        .search-div {
          display: flex;
          border-radius: 9.977px;
          align-items: center;

          column-gap: 12px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: var(--lightwhite);
          padding: 15px;

          .p-dropdown {
            width: 100%;
          }

          input {
            width: 100%;
            border: none;
            background: transparent;
          }

          .SG-datepicker {
            width: 100%;
            margin: 0px;
            .SG-datepicker__day,
            .SG-datepicker__year,
            .SG-datepicker__month {
              &:hover {
                background-color: var(--primary);
                color: white;
              }
            }
            .SG-datepicker__navigation {
              button {
                &:hover {
                  background-color: var(--primary);
                  color: white;
                }
              }
            }
            .SG-datepicker__today:hover {
              color: var(--primary);
            }
            .SG-datepicker__day.today {
              color: var(--primary) !important;
            }
            .SG-datepicker__day.selected {
              background-color: var(--primary);
              color: white;
            }
            .SG-datepicker__year.selected,
            .SG-datepicker__month.selected {
              background-color: var(--primary);
              color: white;
            }
            .SG-datepicker__input-container {
              width: 100%;
              .calendar-icon {
                cursor: pointer;
                fill: var(--primary);
              }
              input {
                padding: 0px;
              }
            }
          }
        }
      }
    }
    .contacts-inner {
      display: flex;
      flex-direction: column;
      row-gap: 13px;
      padding: 10px 20px;
      background: var(--white);
      border-radius: 16px;
      .contactTable-outer {
        overflow-x: auto;
        .contact-table {
          min-width: 965px;
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          .table-head {
            background: rgba(103, 103, 103, 0.1);
            display: flex;
            align-items: center;
          }
          .table-body {
            background: rgba(103, 103, 103, 0.05);
            display: flex;
            flex-direction: column;
            max-height: 442px;
            overflow-y: auto;
            padding-bottom: 15px;
            .contact-detail {
              display: flex;
              align-items: center;
              border-bottom: 1px solid #dbdbdb;
            }
          }
          .table-body,
          .table-head {
            border-radius: 16px;

            .second,
            .third,
            .forth,
            .fifth {
              padding: 20px 14px 20px 20px;
            }

            .second {
              width: 30%;
            }
            .third {
              width: 17%;
            }
            .forth {
              width: 23%;
            }
            .fifth {
              width: 15%;
              display: flex;
              align-items: center;
              column-gap: 10px;
              img {
                width: 25px;
                height: 25px;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}
.compaigndetail-modal {
  .modal-dialog {
    max-width: 967px !important;
    .modal-content {
      .modal-body {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        .detailModal-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          column-gap: 20px;
          .left {
            display: flex;
            flex-direction: column;
            row-gap: 2px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .compaignsInrun-sec {
    .head {
      column-gap: 20px;
      button {
        padding: 10px 15px !important;
        width: 80px !important;
      }
    }
  }
}
