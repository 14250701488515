.advancedscript-outer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .topTabs-outer {
    overflow-x: auto;

    .top-tabs {
      display: flex;
      align-items: center;
      column-gap: 10px;
      width: 561px;
      margin: 0px auto;
      .active-div {
        background-color: var(--primary) !important;
      }
      .active-agent {
        color: var(--primary);
      }
      .tab-agent {
        color: var(--grey);
      }
      .top-tab {
        display: flex;
        align-items: center;
        column-gap: 6px;
        .number-div {
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background-color: var(--grey);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          p {
            color: var(--white);
          }
        }
      }
      .top-line {
        max-width: 85px;
        min-width: 30px;
        width: 100%;
        height: 1px;
        background-color: var(--grey);
      }
    }
  }
  .advancescript-sec {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    border-radius: 9.977px;
    background: var(--white);
    padding: 24px 20px;
    // height: calc(100vh - 165px);
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;
    }
    .range-sec {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 41px 36px;
      .range-div {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .opacity-light {
          opacity: 0.2;
        }
        .opacity-dark {
          opacity: 1;
        }
        &:last-child {
          row-gap: 6px;
        }
        .detail {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 30px;
          .value {
            padding: 4px 16px;
            border-radius: 8px;
            background: var(--lightwhite);
          }
        }
        input[type="range"] {
          -webkit-appearance: none;
          appearance: none;
          width: 100%;
          cursor: pointer;
          outline: none;
          border-radius: 15px;
          height: 6px;
          background: var(--secondary);
          opacity: 0.5;
        }

        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          height: 17px;
          width: 17px;
          background-color: var(--secondary);
          border-radius: 50%;
          border: 3px solid var(--primary);
          transition: 0.2s ease-in-out;
        }
        .model-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 30px;
          padding: 15px 29px;
          border-radius: 9.977px;
          background: var(--lightwhite);
          position: relative;
          cursor: pointer;

          svg {
            transition: all 0.3s linear;
          }
          .model-content {
            position: absolute;
            display: flex;
            flex-direction: column;
            padding-bottom: 7px;
            left: 0;
            right: 0;
            top: 65px;
            border-radius: 9.977px;
            background: var(--lightwhite);
            transition: all 0.3s linear;
            p {
              padding: 12px 28px;
              cursor: pointer;
              border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
          }
          .modal-hide {
            display: none;
          }
          .modal-show {
            display: block;
          }
        }

        // input[type="range"]::-moz-range-thumb {
        //   height: 17px;
        //   width: 17px;
        //   background-color: var(--secondary);
        //   border-radius: 50%;
        //   border: 3px solid var(--primary);
        //   transition: 0.2s ease-in-out;
        // }
      }
    }
    .dates {
      max-width: 500px;
      width: 100%;
      .dates-inner {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        .search-div {
          display: flex;
          border-radius: 9.977px;
          align-items: center;

          column-gap: 12px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: var(--lightwhite);
          padding: 15px;

          .p-dropdown {
            width: 100%;
          }

          input {
            width: 100%;
            border: none;
            background: transparent;
          }

          .SG-datepicker {
            width: 100%;
            margin: 0px;
            .SG-datepicker__day,
            .SG-datepicker__year,
            .SG-datepicker__month {
              &:hover {
                background-color: var(--primary);
                color: white;
              }
            }
            .SG-datepicker__navigation {
              button {
                &:hover {
                  background-color: var(--primary);
                  color: white;
                }
              }
            }
            .SG-datepicker__today:hover {
              color: var(--primary);
            }
            .SG-datepicker__day.today {
              color: var(--primary) !important;
            }
            .SG-datepicker__day.selected {
              background-color: var(--primary);
              color: white;
            }
            .SG-datepicker__year.selected,
            .SG-datepicker__month.selected {
              background-color: var(--primary);
              color: white;
            }
            .SG-datepicker__input-container {
              width: 100%;
              .calendar-icon {
                cursor: pointer;
                fill: var(--primary);
              }
              input {
                padding: 0px;
              }
            }
          }
        }
      }
    }
    .AddLink {
      display: flex;
      align-items: baseline;
      gap: 20px;
    }
    .contactTable-outer {
      overflow-x: auto;
      .contact-table {
        min-width: 1100px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .table-head {
          background: rgba(103, 103, 103, 0.1);
          display: flex;
          align-items: center;
        }
        .table-body {
          background: rgba(103, 103, 103, 0.05);
          display: flex;
          flex-direction: column;
          max-height: 500px;
          overflow-y: auto;
          padding-bottom: 15px;
          .active {
            background: linear-gradient(90deg, #0ca55a 0%, #344e41 100%);
            color: white;
            p {
              color: white;
            }
            img {
              filter: invert(1) !important;
            }
          }
          .contact-detail {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #dbdbdb;
            cursor: pointer;
          }
        }
        .table-body,
        .table-head {
          border-radius: 16px;

          .second,
          .third,
          .first {
            padding: 20px 14px 20px 20px;
          }

          .first {
            width: 25%;
          }
          .second {
            width: 15%;
          }
          .third {
            width: 45%;
          }
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
    }
  }
}
.advance-save {
  .modal-dialog {
    max-width: 664px !important;
    .modal-content {
      .modal-body {
        padding: 75px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 27px;
        div {
          display: flex;
          flex-direction: column;
          row-gap: 9px;
          align-items: center;
        }
      }
    }
  }
}
.phone-modal {
  .modal-dialog {
    max-width: 664px !important;
    .modal-content {
      .modal-body {
        padding: 50px 20px;
        position: relative;
        .close-icon {
          position: absolute;
          top: 24px;
          right: 24px;
        }
        .modal-inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 37px;
          width: 100%;
          .number-select {
            display: flex;
            flex-direction: column;
            row-gap: 13px;
            align-items: center;
            max-width: 419px;
            width: 100%;
            margin: 0px auto;
            .ist {
              display: flex;
              flex-direction: column;
              row-gap: 6px;
              width: 100%;
              position: relative;

              .number-selector {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px;
                border-radius: 9.977px;
                background: var(--Background, #fafafa);
                .number-options {
                  position: absolute;
                  top: 80px;
                  left: 0;
                  right: 0;
                  border-radius: 8px;
                  background: var(--Background, #fafafa);
                  max-height: 190px;
                  overflow-y: auto;
                  p {
                    padding: 20px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                  }
                }
                .active {
                  opacity: 1;
                  z-index: 2;
                }
                .deactive {
                  opacity: 0;
                  z-index: -2;
                }
              }
            }
          }
        }
      }
    }
  }
}
.call-detail {
  .modal-dialog {
    max-width: 664px !important;
    .modal-content {
      .modal-body {
        padding: 60px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 27px;
        div {
          display: flex;
          flex-direction: column;
          row-gap: 9px;
          align-items: center;
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .advancedscript-outer {
    .advancescript-sec {
      .bottom {
        button {
          font-size: 18px;
          padding: 15px !important;
          width: 120px !important;
          span {
            line-height: 100% !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .advancedscript-outer {
    .advancescript-sec {
      row-gap: 20px;
      .range-sec {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px 20px;
        .range-div {
          .detail {
            column-gap: 10px;
          }
          .model-div {
            column-gap: 20px;
            padding: 15px 20px;
          }
        }
      }
    }
  }
  .advance-save {
    .modal-dialog {
      max-width: 500px !important;
      .modal-content {
        .modal-body {
          padding: 50px 20px;
          row-gap: 20px;
        }
      }
    }
  }
  .call-detail {
    .modal-dialog {
      max-width: 500px !important;
      .modal-content {
        .modal-body {
          padding: 50px 20px;
          row-gap: 20px;
          div {
            img {
              width: 80px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .advancedscript-outer {
    .advancescript-sec {
      row-gap: 20px;
      .AddLink {
        flex-direction: column;
        gap: 10px;
        button {
          margin-left: auto !important;
        }
      }
      .head {
        button {
          padding: 10px 15px !important;
          width: 80px !important;
        }
      }
      .range-sec {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        .range-div {
          .detail {
            column-gap: 10px;
          }
          .model-div {
            column-gap: 20px;
            padding: 15px 20px;
          }
        }
      }
      .bottom {
        button {
          font-size: 16px;
          padding: 13px !important;
          width: 100px !important;
        }
      }
    }
  }
  .advance-save {
    .modal-dialog {
      max-width: 500px !important;
      .modal-content {
        .modal-body {
          padding: 50px 20px;
          row-gap: 20px;
        }
      }
    }
  }
}
