button {
  text-align: center;
  border-radius: 8px;
  font-style: normal;
  line-height: normal;
  border: 1px solid var(--primary);
  font-family: "Carmen Sans Medium";
  font-weight: 500;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;
  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
  span {
    line-height: normal;
  }
  &.primary-button {
    background: var(--gradient);
    color: var(--white);
    transition: none;
  }
  &.danger-button {
    background: red;
    color: var(--white);
    border: 1px solid red;
  }
  &.secondary-btn {
    background: var(--white);
    color: var(--primary);
    &:hover {
      color: var(--white);
      background: var(--gradient);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.fs-20 {
  font-size: 20px;
}
.fs-18 {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px;
}

@media screen and (max-width: 1440px) {
  .fs-20 {
    font-size: 18px;
  }
  .fs-18 {
    font-size: 16px;
  }
  .fs-16 {
    font-size: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .fs-20 {
    font-size: 16px;
  }
  .fs-18 {
    font-size: 15px;
  }
  .fs-16 {
    font-size: 14px;
  }
}
