.createCompaign-sec {
  border-radius: 9.977px;
  background: var(--white);
  padding: 20px 20px 42px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  .creation-content {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    .ist {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 19px;
      .ist-inner {
        display: flex;
        flex-direction: column;
        row-gap: 9px;
        .select-agent {
          position: relative;
          border-radius: 9.977px;
          background: var(--lightwhite);
          .select-inner {
            padding: 12px 28px;

            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 30px;
          }
          h4 {
            line-height: 166%;
          }
          svg {
            width: 24px;
            height: 24px;
            object-fit: cover;
          }
          .select-options {
            position: absolute;
            border-radius: 16px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            background: var(--white);
            padding: 16px 16px 24px 16px;
            left: 0;
            right: 0;
            top: 70px;
            z-index: 3;
            .agents {
              max-height: 250px;
              overflow-y: auto;
            }
            p,
            h5 {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              line-height: 187%;
            }
            p {
              padding: 5px 0px;
            }
            h5 {
              padding: 12px 20px;
              cursor: pointer;
              transition: all 0.2s linear;
              &:hover {
                background: var(--lightwhite);
              }
            }
            .search-bar {
              display: flex;
              align-items: center;
              column-gap: 12px;
              padding: 12px 20px;
              border-radius: 9.977px;
              background: var(--lightwhite);
              input {
                width: 100%;
                outline: none;
                border: none;
                background: transparent;
              }
            }
          }
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
@media screen and (max-width: 800px) {
  .createCompaign-sec {
    padding: 20px 15px;
    row-gap: 15px;
    .creation-content {
      row-gap: 10px;
      .inputdash-sec {
        textarea {
          line-height: 166%;
          min-height: 100px !important;
        }
      }
      .ist {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        .ist-inner {
          .select-agent {
            .select-inner {
              padding: 10px 20px;
              column-gap: 20px;
            }

            svg {
              width: 20px;
              height: 20px;
            }
            .select-options {
              position: absolute;
              border-radius: 16px;
              border: 1px solid rgba(0, 0, 0, 0.2);
              background: var(--white);
              padding: 15px 15px 20px 15px;
              left: 0;
              right: 0;
              top: 55px;

              p,
              h5 {
                line-height: 150%;
              }

              .search-bar {
                column-gap: 10px;
                padding: 10px 15px;
              }
            }
          }
        }
      }
      .bottom {
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .createCompaign-sec {
    .creation-content {
      .ist {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
