.contactlist-sec {
  border-radius: 16px;
  background: var(--white);
  padding: 20px 10px 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }
  .contacts {
    padding: 10px 20px 20px 20px;
    border-radius: 16px;
    background: var(--lightwhite);
    display: flex;
    flex-direction: column;
    .inner-top {
      div {
        padding: 10px 65px;
        border-radius: 16px 16px 0px 0px;
        background: var(--white);
      }
    }
    .contacts-inner {
      display: flex;
      flex-direction: column;
      row-gap: 23px;
      background: var(--white);
      padding: 20px;
      .topbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 30px;
        .search-bar {
          display: flex;
          align-items: center;
          column-gap: 12px;
          padding: 12px 20px;
          border-radius: 9.977px;
          background: var(--lightwhite);
          width: 100%;
          max-width: 367px;
          input {
            width: 100%;
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .select-agent {
          position: relative;
          border-radius: 9.977px;
          background: var(--lightwhite);
          .select-inner {
            padding: 7px 10px;

            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 30px;
          }

          svg {
            width: 24px;
            height: 24px;
            object-fit: cover;
          }
          .select-options {
            position: absolute;
            border-radius: 16px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            background: var(--white);
            left: 0;
            right: 0;
            top: 45px;
            z-index: 3;
            padding-bottom: 5px;
            .creator-select {
              max-height: 130px;
              overflow-y: auto;
            }
            p {
              line-height: 100%;
              display: flex;
              padding: 7px 10px;
              cursor: pointer;
            }

            label {
              padding: 12px 20px;
              cursor: pointer;
              transition: all 0.2s linear;
              &:hover {
                background: var(--lightwhite);
              }
            }
            .search-bar {
              padding: 4px 10px;
              border-radius: 9.977px;
              background: var(--lightwhite);
              border-radius: 16px 16px 0px 0px;

              input {
                width: 100%;
                outline: none;
                border-radius: 8px;
                border: 0.5px solid rgba(103, 103, 103, 0.2);
                background: transparent;
                padding: 7px;
                font-size: 12px;
              }
            }
          }
        }
      }
      .contactTable-outer {
        overflow-x: auto;
        .contact-table {
          min-width: 700px;
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          .table-head {
            background: rgba(103, 103, 103, 0.1);
            display: flex;
            align-items: center;
          }
          .table-body {
            background: rgba(103, 103, 103, 0.05);
            display: flex;
            flex-direction: column;
            max-height: 442px;
            overflow-y: auto;
            padding-bottom: 15px;
            .contact-detail {
              display: flex;
              align-items: center;
              border-bottom: 1px solid #dbdbdb;
            }
          }
          .table-body,
          .table-head {
            border-radius: 16px;

            .ist,
            .second,
            .third,
            .forth {
              padding: 20px 14px 20px 20px;
            }
            // .ist {
            //   width: 5%;
            //   margin-top: auto;
            //   input[type="checkbox"] {
            //     width: 18px;
            //     height: 18px;
            //     border-radius: 4px;
            //     border: 1px solid var(--grey);
            //     background: var(--lightwhite);
            //     accent-color: var(--primary);
            //     cursor: pointer;
            //     margin-top: auto;
            //   }
            // }
            .second {
              width: 70%;
            }
            .third {
              width: 15%;
            }
            .forth {
              width: 15%;
              img {
                width: 30px;
                height: 30px;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}
.contact-modal {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 1070px !important;
  }
  .modal-content {
    .modal-body {
      padding: 27px 111px;
      display: flex;
      flex-direction: column;
      row-gap: 35px;
      position: relative;
      .close-btn {
        position: absolute;
        top: 33px;
        right: 33px;
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
      .contactsModal-inner {
        padding: 16px 20px;
        border-radius: 16px;
        background: var(--lightwhite);
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        overflow-x: auto;
        width: 100%;

        .topbar {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .search-bar {
            display: flex;
            align-items: center;
            column-gap: 12px;
            padding: 12px 20px;
            border-radius: 9.977px;
            background: var(--lightwhite);
            border: 1px solid rgba(103, 103, 103, 0.2);

            width: 100%;
            max-width: 367px;
            input {
              width: 100%;
              outline: none;
              border: none;
              background: transparent;
            }
          }
        }
        .contactmodalTable-outer {
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          .contactmodal-table {
            overflow-x: auto;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            width: 100%;
            .table-body {
              min-width: 700px;

              background: rgba(103, 103, 103, 0.05);
              display: flex;
              flex-direction: column;
              max-height: 400px;
              overflow-y: auto;
              padding-bottom: 15px;

              .contact-detail {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #dbdbdb;
              }
            }
            .table-body,
            .table-head {
              border-radius: 16px;

              .ist,
              .second,
              .third,
              .forth {
                padding: 20px 14px 20px 20px;
              }
              .ist {
                width: 5%;
                margin-top: auto;
                input[type="checkbox"] {
                  width: 18px;
                  height: 18px;
                  border-radius: 4px;
                  border: 1px solid var(--grey);
                  background: var(--lightwhite);
                  accent-color: var(--primary);
                  cursor: pointer;
                  margin-top: auto;
                }
              }
              .second {
                width: 40%;
              }
              .third {
                width: 25%;
              }
              .forth {
                width: 35%;
                img {
                  width: 30px;
                  height: 30px;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .contactlist-sec {
    .contacts {
      .contacts-inner {
        row-gap: 15px;
        padding: 15px;
        .topbar {
          .search-bar {
            padding: 10px 15px;
            max-width: 300px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contactlist-sec {
    border-radius: 16px;
    background: var(--white);
    padding: 20px 10px 20px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .contacts {
      .contacts-inner {
        row-gap: 20px;
        .topbar {
          column-gap: 20px;
          .search-bar {
            max-width: 230px;
          }
        }
      }
    }
  }
  .contact-modal {
    .modal-content {
      .modal-body {
        padding: 20px 50px;
        row-gap: 20px;
        .close-btn {
          position: absolute;
          top: 20px;
          right: 20px;
        }
        .contactsModal-inner {
          .topbar {
            .search-bar {
              column-gap: 10px;
              padding: 10px 15px;

              max-width: 300px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contactlist-sec {
    border-radius: 16px;
    background: var(--white);
    padding: 20px 10px 20px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .contacts {
      padding: 10px 20px 20px 20px;
      border-radius: 16px;
      background: var(--lightwhite);
      display: flex;
      flex-direction: column;
      .inner-top {
        div {
          padding: 10px 65px;
          border-radius: 16px 16px 0px 0px;
          background: var(--white);
        }
      }
      .contacts-inner {
        display: flex;
        flex-direction: column;
        row-gap: 23px;
        background: var(--white);
        padding: 20px;
        .topbar {
          display: flex;
          align-items: center;
          flex-direction: column;
          row-gap: 10px;
          .search-bar {
            width: 100%;
            max-width: unset;
            input {
              width: 100%;
              outline: none;
              border: none;
              background: transparent;
            }
          }
          .select-agent {
            width: 100%;
          }
        }
      }
    }
  }

  .contact-modal {
    .modal-content {
      .modal-body {
        padding: 20px 15px;
        row-gap: 15px;

        .close-btn {
          position: absolute;
          top: 15px;
          right: 15px;
          width: 20px;
          height: 20px;
        }
        .contactsModal-inner {
          padding: 10px 15px;
          row-gap: 15px;

          .topbar {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            row-gap: 10px;
            .search-bar {
              max-width: unset;
            }
            button {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
